// require('./riot_js/pages/home/home-page.tag');   
// require('./riot_js/pages/home/home.js'); 
// require('./riot_js/header/footer.tag');
// require('./riot_js/loading.tag');  

// require("./css/style.css");
// require("./css/style2.css");
// require("./css/modal.css");
// require("./css/template.css");




// assets for front-end




var currentTag = null;
var routes = {};

function mount(tag, options) {
  currentTag && currentTag.unmount(true);
  currentTag = riot.mount('#content', tag, options)[0];
}

function handler(collection, id, action) {
  var fn = routes[collection || 'home'];
  var elmnt = document.getElementById("myDIV");
$('main').scrollTop(0);
  
  fn ? fn(id, action) : mount('not-found-page');
}


  var tags = riot.mount('*')
     riot.route(handler)
     riot.route.start(true)